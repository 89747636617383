* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  color: #f6f6f6;
  -webkit-font-smoothing: antialiased !important;
  background-image: url(./assets/img/ukraine-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 100vh;
}

._flex {
  display: flex;
}

._flex-column {
  flex-direction: column;
}

._hide {
  display: none;
}

._m-auto {
  margin: auto!important;
}

.bold {
  font-weight: bold;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100%;
  justify-content: space-between;
  opacity: 0;
  animation: ani 1s forwards;
}

header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.qr-reader {
  max-width: 500px;
  width: 100%;
  margin: auto;
}

.scanBlock {
  margin-bottom: 20px;
}

.scanBlock video {
  object-fit: cover;
  border-radius: 20px;
  min-width: 235px;
  position: relative;
  opacity: 0;
  animation: ani 2s forwards;
}

.scanFinder {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 20px;
  border: 4px solid rgb(248 201 26);
  opacity: 0;
  animation: ani 2s forwards 2s;
}

.coords {
  text-align: center;
  margin-bottom: 5px;
}

.button {
  margin: 0 auto 20px auto;
  padding: 10px;
  background: #f6f6f6;
  font-size: 16px;
  border-radius: 10px;
  text-decoration: none;
  width: 100%;
  text-align: center;
  color: #333;
  cursor: pointer;
  max-width: 260px;
  line-height: 1.5;
  transition: box-shadow .3s linear;
  box-shadow: 2px 2px 9px 0 #e4e4e4;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0;
  animation: ani 2s forwards;
}

.button--download {
  background: #35a135;
  box-shadow: 2px 2px 9px 0#35a135;
  color: white;
}

.button:hover {
  box-shadow: 5px 5px 9px 0 #646262;
}

.noty {
  color: black;
  font-weight: bold;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 15px;
  border-radius: 20px 0px 0px 0px;
  background: #5d9ffb;
  opacity: 0;
  animation: ani .3s forwards;
}

@keyframes ani {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
